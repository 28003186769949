<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow-y: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <ContractSettingLeftMenu></ContractSettingLeftMenu>
        <div v-if="!bulkUploadId" class="h-100 flex-auto text-align-left">
          <div data-test="settings-folders-page" class="flex flex-column h-100 w-100 pr4 bzbb">
            <MetaRightSideHeader>
              <template #title>
                批量上传已签署文件
              </template>
              <template #middle-right>
                <el-button type="primary"  @click="uploadDialogVisible = true">上传</el-button>
              </template>
              <template #bottom-tip>
                一次上传多个已签署合同（支持 Word和 PDF）。 要上传的合同必须包含在一个 Zip文件中。
              </template>
            </MetaRightSideHeader>
            <section class="flex flex-column flex-auto overflow-y-auto  bg-white shadow">
              <MetaListTable
              :table-data="bulkUploadList"
              :table-height="'100%'"
              :empty-text="'无任务'"
              :show-checkbox="true"
              @cell-click="onBulkUploadClicked($event)"
              >
                <template #header>
                  <div class="action">
                    <div class="selector-wrap">
                      <MetaListSelector
                          :list="bulkUploadList"
                          :show-operation-action-btn="false"
                      ></MetaListSelector>
                    </div>
                  </div>
                </template>
                <template #default>
                  <el-table-column
                    label="状态"
                    prop="status"
                    align="left"
                    min-width="150"
                    sortable
                  >
                    <template
                    #default="{row}"
                    >
                      <i class="material-icons v-mid md-size2"
                         :class="row.status === 1 ? 'soft-green' : 'concord-blue'"
                         v-html="row.status === 1 ? 'check_circle' : 'update'">
                      </i>
                      <span class="ml2 truncate" v-text="row.status === 1 ? '已完成' : '进行中'">
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="文件名"
                      prop="fileName"
                      align="left"
                      width="150"
                      sortable
                  >
                    <template #default="{row}">
                      <span title="testBulkUpload.zip">
                            {{row.fileName}}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="标签"
                      prop="tags"
                      align="left"
                      min-width="150"
                      sortable
                  >
                    <template #default="{row}">
                      <span title="">{{JSON.parse(row.tags).join(',')}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="创建者"
                      prop="creator"
                      align="left"
                      min-width="150"
                      sortable
                  >
                    <template #default="{row}">
                      <span>
                            {{row.creator}}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="日期"
                      prop="createTime"
                      align="left"
                      min-width="150"
                      sortable
                  >
                    <template #default="{row}">
                      <span>
                        {{row.createTime}}
                      </span>
                    </template>
                  </el-table-column>
                </template>

              </MetaListTable>
            </section>
          </div>

          <!---->
          <div class="ember-view"></div>
        </div>
        <div v-else-if="bulkUploadDetail != null" class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pr4">
            <MetaRightSideHeader style="height: 9rem">
              <template #title>
                <div class="file-uoload-info-title">
                  <span>
                  {{bulkUploadDetail.fileName}}
                </span>
                  <div class="flex items-center ml3 pl3 bl b--light-moon-gray">
<!--                    <i class="material-icons v-mid md-size2 soft-green">-->
<!--                      check_circle-->
<!--                    </i>-->
                    <i class="material-icons v-mid md-size2"
                       :class="bulkUploadDetail.status === 1 ? 'soft-green' : bulkUploadDetail.status === 2 ? 'concord-orange' : 'dark-red'"
                       v-html="bulkUploadDetail.status === 1 ? 'check_circle' : bulkUploadDetail.status === 2 ? 'info' : 'error'">
                    </i>
                    <span class="ml2" v-html="bulkUploadDetail.status === 1 ? '已完成' : bulkUploadDetail.status === 2 ? '进行中' : '错误'">
                    </span>
                  </div>
                </div>

              </template>
              <template #middle-right>
                <div class="ml-auto tr gray fw4 ttu f6 mr20">
                  {{bulkUploadDetail.creator}},&nbsp;{{bulkUploadDetail.createTime}},&nbsp;上传 id:&nbsp;{{bulkUploadDetail.id}}
                </div>
              </template>
              <template #bottom-tip>
                <div>状态筛选：</div>
              </template>
              <template #bottom-tip-long>
                <div class="flex">
                  <section class="flex flex-columns pr3 pt2 pb1 concord-dark-gray w-100">
                    <div class="w-90 flex flex-wrap max-h4 black fw4" style="overflow: hidden">
                      <div v-if="statusFilterCompleted || statusFilterInProgress || statusFilterError"
                           class="ba bg-white br2 b--light-gray flex flex-columns items-center mw-90 mb1 pl2 pr1 pv1 size-medium mr2">
                      <span class="gray">
                        状态:
                      </span>
                        <span class="ml2 mr2 truncate" v-html="getFilterStatusText()"></span>
                        <button @click="statusFilterCompleted = false; statusFilterInProgress = false; statusFilterError = false"
                                class="bn bg-transparent fw4 hover-concord-blue pa0 pointer outline-0 silver" type="button">
                          <i class="material-icons md-size2 pointer v-mid">
                            close
                          </i>
                        </button>
                      </div>

                      <el-dropdown v-if="statusFilterCompleted || statusFilterInProgress || statusFilterError" :hide-on-click="false" trigger="click">
                        <div>
                          <div id="attach-select-ember70">
                            <div data-test-trigger=""
                                 data-test-disabled="false"
                                 aria-disabled="false"
                                 aria-expanded="false"
                                 aria-haspopup=""
                                 class="outline-0 size-medium ph2 f5 pointer flex items-center w-100 ba bg-white br2 b--light-gray flex flex-columns hover-bg-light-gray items-center justify-center pointer size-medium" style="min-width: 36px;" role="button" tabindex="0">
                              <i class="material-icons md-size2 pv1 v-mid">
                                add
                              </i>
                              <i aria-hidden="true" class="material-icons ml-auto md-size2">
                                arrow_drop_down
                              </i>
                            </div>
                          </div>
                        </div>
                        <template #dropdown style="margin-top: 3px">
                          <el-dropdown-menu>
                            <el-checkbox class="ph3 pv2 pointer flex items-center ember-view" v-model="statusFilterCompleted">
                              <div class="flex items-center ml2">
                                <i class="material-icons v-mid md-size2 soft-green">
                                  check_circle
                                </i>
                                <span class="ml2">
                                已完成
                              </span>
                              </div>
                            </el-checkbox>
                            <el-checkbox class="ph3 pv2 pointer flex items-center ember-view" v-model="statusFilterInProgress">
                              <div class="flex items-center ml2">
                                <i class="material-icons v-mid md-size2 concord-orange">
                                  info
                                </i>
                                <span class="ml2">
                                进行中
                              </span>
                              </div>
                            </el-checkbox>
                            <el-checkbox class="ph3 pv2 pointer flex items-center ember-view" v-model="statusFilterError">
                              <div class="flex items-center ml2">
                                <i class="material-icons v-mid md-size2 dark-red">
                                  error
                                </i>
                                <span class="ml2">
                                失败
                              </span>
                              </div>
                            </el-checkbox>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>


                    </div>

<!--                    <div v-if="statusFilterCompleted || statusFilterInProgress || statusFilterError"-->
<!--                         @click="statusFilterCompleted = false; statusFilterInProgress = false; statusFilterError = false"-->
<!--                         class="w-10 mb1 pointer underline-hover fw4 justify-center flex items-center mid-gray"-->
<!--                         data-ember-action=""-->
<!--                         data-ember-action-84="84">-->
<!--                      清除所有条件-->
<!--                    </div>-->

                  </section>
                </div>
              </template>
            </MetaRightSideHeader>
            <section id="classic-page-content" class="flex flex-column flex-auto overflow-y-auto  bg-white shadow">
              <div class="flex-basis-100 flex-auto flex-columns w-100">
                  <MetaListTable
                  :table-data="bulkUploadDetail.bulkUploadFileList"
                  :table-height="'100%'"
                  :show-checkbox="true"
                  @cell-click="onItemClicked($event.item)"
                  >
                    <template #header>
                      <div class="flex fw4 action">
                        <div class="selector-wrap">
                          <MetaListSelector
                              :list="bulkUploadDetail.bulkUploadFileList"
                              :show-operation-action-btn="false"
                          ></MetaListSelector>
                        </div>

                        <el-dropdown :hide-on-click="false" trigger="click">
                      <span class="el-dropdown-link">
                        <div class="pv1 nowrap pointer">
                      <div>
                        <div id="attach-select-ember811">
                          <div data-test-trigger=""
                               data-test-disabled="false"
                               aria-disabled="false"
                               aria-expanded="false"
                               aria-haspopup=""
                               class="outline-0 size-medium ph2 f5 pointer flex items-center w-100 border-box fw4 mr2" role="button" tabindex="0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 32 32" class="concord-blue fill v-mid ln-title"><path d="M24 2.667H8a2.67 2.67 0 00-2.667 2.667v5.333c0 .341.131.683.391.943L12 17.887V28a1.335 1.335 0 002.277.942l5.333-5.333c.251-.249.391-.588.391-.943v-4.781l6.276-6.276c.26-.26.391-.6.391-.943V5.333a2.67 2.67 0 00-2.667-2.667z"></path></svg>
                            <span class="concord-blue truncate">
                                根据状态筛选
                            </span>
                            <i aria-hidden="true" class="material-icons ml-auto md-size2">
                              arrow_drop_down
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                      </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-checkbox class="ph3 pv2 pointer flex items-center ember-view block" v-model="statusFilterCompleted">
                                <div class="flex items-center ml2">
                                  <i class="material-icons v-mid md-size2 soft-green">
                                    check_circle
                                  </i>
                                  <span class="ml2">
                                已完成
                              </span>
                                </div>
                              </el-checkbox>
                              <el-checkbox class="ph3 pv2 pointer flex items-center ember-view block" v-model="statusFilterInProgress">
                                <div class="flex items-center ml2">
                                  <i class="material-icons v-mid md-size2 concord-orange">
                                    info
                                  </i>
                                  <span class="ml2">
                                进行中
                              </span>
                                </div>
                              </el-checkbox>
                              <el-checkbox class="ph3 pv2 pointer flex items-center ember-view block" v-model="statusFilterError">
                                <div class="flex items-center ml2">
                                  <i class="material-icons v-mid md-size2 dark-red">
                                    error
                                  </i>
                                  <span class="ml2">
                                失败
                              </span>
                                </div>
                              </el-checkbox>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>

                        <div class="flex items-center mr3">
                          <i class="material-icons v-mid md-size2 soft-green">
                            check_circle
                          </i>
                          <span class="ml2">已完成:{{bulkUploadDetail.completed}}</span>
                        </div>
                        <div class="flex items-center mr3">
                          <i class="material-icons v-mid md-size2 concord-orange">
                            info
                          </i>
                          <span class="ml2">进行中:{{bulkUploadDetail.inProgress}}</span>
                        </div>
                        <div class="flex items-center mr3">
                          <i class="material-icons v-mid md-size2 dark-red">
                            error
                          </i>
                          <span class="ml2">失败:{{bulkUploadDetail.error}}</span>
                        </div>
                      </div>
                    </template>
                    <template #default>
                      <el-table-column
                        label="状态"
                        prop="status"
                        min-width="150"
                        align="left"
                        sortable
                      >
                       <template #default="{row}">
                         <div class="et-cell-container">
                           <div class="et-depth-indent et-depth-placeholder depth-0"></div>

                           <div class="et-cell-content">
                             <div class="flex items-center">
                               <i class="material-icons v-mid md-size2 " :class="{'soft-green':row.status===1,'concord-orange':row.status===2,'dark-red':row.status!==1&&row.status!==2}"
                                  v-html="row.status === 1 ? 'check_circle': row.status === 2 ? 'info': 'error'">
                               </i>
                               <span class="ml2" v-html="row.status === 1 ? '已完成': row.status === 2 ? '进行中': '错误'">
                          </span>
                             </div>

                           </div>
                         </div>
                       </template>
                      </el-table-column>
                      <el-table-column
                          label="文件名"
                          prop="item.title"
                          min-width="150"
                          align="left"
                          sortable
                      >
                        <template #default="{row}">
                          <span>
                            {{row.item.title}}
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column
                          label="日期"
                          prop="item.createTime"
                          min-width="150"
                          align="left"
                          sortable
                      >
                        <template #default="{row}">
                          <span>
                            {{row.item.createTime}}
                          </span>
                        </template>
                      </el-table-column>
                    </template>

                  </MetaListTable>

                </div>

            </section>
          </div>

        </div>
      </div>
    </div>


    <el-dialog
        v-model="uploadDialogVisible"
        class="upload-dialog"
        title=""
        width="70%">
      <form id="bulkUploadForm" enctype="multipart/form-data" class="cf fw4 tl">
        <div class="ph4 pv4">

          <div class="flex flex-column">
            <h1 class="ma0 fw4 mid-gray">
              上传多个已签署合同
            </h1>
            <p class="mb0 mt2">
              选择最大为500MB的Zip文件，每份合同都将以其文件名作为标题。
            </p>
          </div>

        </div>

        <div class="black ph4">

          <table class="collapse dt--fixed">
            <tbody>
            <tr>
              <td class="pt3">
                压缩文件*
              </td>
              <td class="w-70">
<!--                <div class="red tr f6 o-0">-->
<!--                  该文件超过了2GB-->
<!--                </div>-->
                <label for="file-input-9221d" class="file-upload ember-view w-100 mb0 f5 b--light-moon-gray" data-test-upload-file-input="">
<!--                <label class="file-upload ember-view w-100 mb0 f5 b&#45;&#45;light-moon-gray" data-test-upload-file-input="">-->
                  <input id="file-input-9221d" accept=".zip"  hidden="" type="file" @change="onFileChange">
                  <span class="ph2 f5 size-medium  b--silver bb--only br--top border-box br1 bg-near-white w-100 flex items-center" style="justify-content: space-between">
                    <span class="truncate concord-dark-gray" v-html="selectedFile != null ? selectedFile.name: '未选择文件'">
                    </span>
                    <span class="f5 mv1 pv1 color-animate hover-concord-dark-blue bg-transparent b--transparent concord-blue ph3 db fw5">
                      选择文件
                    </span>
                  </span>
                </label>
<!--                  <el-upload-->
<!--                      ref="upload"-->
<!--                      class="upload-demo"-->
<!--                      action="https://jsonplaceholder.typicode.com/posts/"-->
<!--                      :auto-upload="false">-->
<!--                    <template #trigger>-->
<!--                      <label class="file-upload ember-view w-100 mb0 f5 b&#45;&#45;light-moon-gray" data-test-upload-file-input="">-->
<!--                      <input id="file-input-9221d" accept=".zip" hidden="" type="file" @change="onFileChange">-->
<!--                      <span class="ph2 f5 size-medium  b&#45;&#45;silver bb&#45;&#45;only br&#45;&#45;top border-box br1 bg-near-white w-100 flex items-center" style="justify-content: space-between">-->
<!--                        <span class="truncate concord-dark-gray" v-html="selectedFile != null ? selectedFile.name: '未选择文件'">-->
<!--                        </span>-->
<!--                        <span class="f5 mv1 pv1 color-animate hover-concord-dark-blue bg-transparent b&#45;&#45;transparent concord-blue ph3 db fw5">-->
<!--                          选择文件-->
<!--                        </span>-->
<!--                      </span>-->
<!--                      </label>-->
<!--                    </template>-->
<!--                    <el-button-->
<!--                        style="margin-left: 10px; display: none"-->
<!--                        size="small"-->
<!--                        type="success"-->
<!--                        @click="startUpload">upload to server</el-button>-->
<!--                    <template #tip>-->
<!--                      <div class="el-upload__tip">-->
<!--                        jpg/png files with a size less than 500kb-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </el-upload>-->

              </td>
            </tr>
            <tr>
              <td>
                标签
              </td>
              <td class="pt2">
                <div aria-expanded="true" aria-disabled="false" aria-busy="false" tabindex="-1" class="dib br--top br1 input-border-bottom-1 outline-0 w-100 bg-near-white fw4 ph2  b--silver hover-b--concord-blue tag-select is-open ember-view" role="combobox">
                  <div class="flex flex-wrap items-center w-100  tag-select-selected-options ember-view">
                    <div aria-current="false" class="truncate fw4 concord-dark-gray flex f5 chips-size-medium  tag-select-selected-option ember-view" v-for="(tag, index) in tags">
                      <div class="flex items-center bg-white br2 mr2 mv1 pointer ba b--concord-light-blue w-100 hover-b--concord-blue hover-bg-concord-light-blue bg-white b--concord-light-blue pa1 ember-view">
                        <span class="truncate" title="aa">
                          {{tag}}
                        </span>
                        <i aria-label="Close" class="concord-blue material-icons md-size1 pointer silver v-mid" role="button" tabindex="0" @click="onTagRemoveClicked(tag, index)">
                          close
                        </i>
                      </div>
                    </div>
                    <input @keyup.enter="onTagInputEnter" id="tagInput" role="searchbox" placeholder="输入标签名(输入完成按下回车添加标签)" maxlength="255" autocomplete="off" aria-multiline="false" class="tag-select-input ember-view f5 chips-size-medium flex-grow-1 outline-0 b--transparent bg-near-white concord-dark-gray placeholder-gray" type="text">
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="uploadDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="startUpload">开始上传</el-button>
        </div>
      </template>
    </el-dialog>
  </div>

</template>

<script>
// 合同的过滤器类型
import config from "../../config";
import {
  getBulkUploads,
  getBulkUpload,
  bulkUpload
} from "../../api/api";
import {ElLoading, ElMessage} from "element-plus";
import {MetaMessage} from "../../util";
import ContractSettingLeftMenu from "../../components/ContractSettingLeftMenu"
import MetaListTable from "../../components/list/MetaListTable";
import MetaListSelector from "../../components/list/MetaListSelector";
import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
import {globalTagClick} from "../../assets/js/tag/tag";

export default {
  name: "bulkUpload",
  components: {MetaRightSideHeader, MetaListTable,MetaListSelector, ContractSettingLeftMenu},
  data: function () {
    return {
      bulkUploadId: this.$route.params.id,
      templateHost: config.baseUrl,
      metaMessage: new MetaMessage(),
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      bulkUploadDialogVisible: false,
      loadingInstance: null,
      uploadDialogVisible: !!this.$route.query.import || false,
      tags: [],
      selectedFile: null,
      bulkUploadList: [],
      bulkUploadDetail: null,
      statusFilter: 1, // 已完成：1；进行中：2；错误：4；已完成+进行中：3，一次类推
      statusFilterCompleted: false,
      statusFilterInProgress: false,
      statusFilterError: false,
    };
  },
  watch: {
    '$route.params.id': function (newValue, oldValue) {
      console.log('watch $route.params.id  oldValue:' + oldValue + "   newValue:"  + newValue);
      if (newValue !== oldValue) {
        this.bulkUploadId = this.$route.params.id;
        this.init();
      }
    },
    'statusFilterCompleted': function (newValue, oldValue) {
      console.log('watch statusFilterCompleted  oldValue:' + oldValue + "   newValue:"  + newValue);
      this.loadBulkUpload();
    },
    'statusFilterInProgress': function (newValue, oldValue) {
      console.log('watch statusFilterInProgress  oldValue:' + oldValue + "   newValue:"  + newValue);
      this.loadBulkUpload();
    },
    'statusFilterError': function (newValue, oldValue) {
      console.log('watch statusFilterError  oldValue:' + oldValue + "   newValue:"  + newValue);
      this.loadBulkUpload();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
      console.log('bulkUpload init this.bulkUploadId === ', this.bulkUploadId);
      if (this.bulkUploadId) {
        this.loadBulkUpload();
      } else {
        this.queryBulkUploads();
      }
    },
    queryBulkUploads: function () {
      getBulkUploads().then(res => {
        console.log('getBulkUploads  res === ', res);
        this.bulkUploadList = res.data.data;
      }).catch(err => {
        console.error('getBulkUploads   err:', err);
      })
    },
    loadBulkUpload: function () {
      const statuses = [];
      if (this.statusFilterCompleted) statuses.push(1);
      if (this.statusFilterInProgress) statuses.push(2);
      if (this.statusFilterError) statuses.push(4);

      getBulkUpload(this.bulkUploadId, statuses.join(",")).then(res => {
        console.log('getBulkUpload  res:', res);
        this.bulkUploadDetail = res.data.data;
      }).catch(err => {
        console.error('getBulkUpload  err:', err);
      })
    },
    statusFilterChanged: function (status) {
      console.log('statusFilterChanged  status === ', status);
      if (status === 1) {
        if ((this.statusFilter & 1) === 1) {
          this.statusFilter -= 1;
        } else {
          this.statusFilter += 1;
        }
      } else if (status === 2) {
        if ((this.statusFilter & 2) === 2) {
          this.statusFilter -= 2;
        } else {
          this.statusFilter += 2;
        }
      } else if (status === 4) {
        if ((this.statusFilter & 4) === 4) {
          this.statusFilter -= 4;
        } else {
          this.statusFilter += 4;
        }
      }
    },
    getFilterStatusText: function () {
      const statusList = [];
      if (this.statusFilterCompleted) statusList.push('已完成');
      if (this.statusFilterInProgress) statusList.push('进行中');
      if (this.statusFilterError) statusList.push('失败');

      return statusList.join('，');
    },
    openNewPage: function (name, query) {
      let newPage = this.$router.resolve({
        name: name,
        query: query
      })
      window.open(newPage.href)
    },
    startUpload: function () {

      this.onTagInputEnter();

      if (this.selectedFile == null) {
        this.metaMessage['error']('请选择需要上传的文件');
        return;
      }
      globalTagClick('bulk-upload-start-upload');
      // bulkUploadForm
      const formData = new FormData()
      formData.append('file', this.selectedFile);
      formData.append("tags", this.tags);
      bulkUpload(formData).then(res => {
        console.log('bulkUpload  res:', res);
        if (res.data.code === 0) {
          this.bulkUploadList.push(res.data.data);
        } else {
          this.metaMessage['error']('上传失败，' + res.data.msg);
        }
      }).catch(err => {
        console.error('bulkUpload  err:', err);
      })
      this.uploadDialogVisible = false;
    },
    onBulkUploadClicked: function (bulkUpload) {
      globalTagClick('bulk-upload-file-detail',{id:bulkUpload.id});
      console.log('onBulkUploadClicked bulkUpload === ', bulkUpload);
      this.$router.push({
        path: '/settings/bulk-upload/' + bulkUpload.id
      })
      // this.bulkUploadId = bulkUpload.id;
      // this.loadBulkUpload();
    },
    onItemClicked: function (item) {
      globalTagClick('bulk-upload-contract-detail',{contractId:item.contractId});
      console.log('onContractVersionClicked item === ', item);
      this.openNewPage('contractView', { id: item.contractId });
    },
    onTagInputEnter: function () {
      const tagInput = document.querySelector('#tagInput');
      const tag = tagInput.value;
      if(tag=='')return;
      const containsTag = this.tags.some((t) => {
        return t === tag;
      });
      if (!containsTag) {
        this.tags.push(tag);
        tagInput.value='';
      }else{
        this.metaMessage.error('标签已存在!');
      }
    },
    onFileChange: function () {
      this.selectedFile = document.getElementById("file-input-9221d").files[0];
      let maxSize = 1024*1024*500;
      if(this.selectedFile.size>maxSize){
        this.metaMessage.error("文件大小不能超过500Mb!")
        document.getElementById("file-input-9221d").value=''
        this.selectedFile=null;
      }
    },
    onTagRemoveClicked: function (tag, index) {
      this.tags.splice(index, 1);
    },
    openLoading: function () {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    }
  },
}
</script>

<style scoped>
.file-uoload-info-title{
  display: flex;
}
/*.silver {*/
/*  color: #999;*/
/*}*/
.dialog-footer{
  padding-right: 28px;
}

  .block {
    display: block;
  }

  .mr20 {
    margin-right: 20px;
  }

</style>
<style>
  .meta-right-side-header .bottom-part {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  .meta-right-side-header .bottom-part-tip-long {
    margin-bottom: 0;
    position: relative;
    width: 80%;
  }

  .meta-right-side-header .bottom-part-tip {
    position: relative;
    margin-bottom: 0;
    bottom: 0;
  }

</style>
